@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

/* :root {
  --primary-color: #069550;
  --secondary-color: #1919a1;
  --neutral-nero-70: #121212;
  --neutral-nero-50: #292929;
  --neutral-nero-30: #aaaaaa;
  --neutral-nero-10: #ededed;
  --positive-color: rgba(153, 255, 202, 0.6);
  --negative-color: rgba(255, 193, 193, 1);
  --neutral-color: rgba(211, 211, 248, 1);
  --warning-color: rgba(255, 239, 153, 1);
} */

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 95%;
}

select {
  appearance: none;
}

.responsive-input {
  width: 460px;
}

.special-input::placeholder {
  color: white;
}

.home-overlay {
  width: 100%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.4);
}

.box-shape {
  height: 200px;
}

.responsive-modal {
  width: 60%;
}

.custom-file-input {
  display: none;
}

.custom-padding {
  padding-inline: 8rem;
}

.custom-size {
  font-size: 0.85rem;
}

.responsive-web {
  width: 1236px;
}

.responsive-input {
  width: 400px;
}

.custom-align {
  align-items: center;
}

.custom-width {
  width: fit-content;
}

/* Responsive to Tablet , Mobile  */

@media only screen and (max-width: 1236px) {
  html,
  body {
    font-size: 82.5%;
  }

  .responsive-input {
    width: 350px;
  }

  .box-shape {
    height: 150px;
  }

  .custom-padding {
    padding-inline: 5rem;
  }

  .responsive-web {
    width: 95%;
  }
}
@media only screen and (max-width: 996px) {
  html,
  body {
    font-size: 82.5%;
  }

  .responsive-input {
    width: 100%;
  }

  .box-shape {
    height: 150px;
  }

  .custom-padding {
    padding-inline: 5rem;
  }

  .responsive-web {
    width: 95%;
  }

  .custom-width {
    width: 100%;
  }
}
@media only screen and (max-width: 778px) {
  html,
  body {
    font-size: 80%;
  }

  .responsive-image {
    width: 10px;
  }

  .responsive-modal {
    width: 70%;
  }

  .box-shape {
    height: 140px;
  }

  .custom-padding {
    padding-inline: 4rem;
  }

  .custom-size {
    font-size: 0.65rem;
  }
}
@media only screen and (max-width: 578px) {
  html,
  body {
    font-size: 80%;
  }

  .responsive-modal {
    width: 97.5%;
  }

  .box-shape {
    height: 150px;
  }

  .custom-size {
    font-size: 0.6rem;
  }
}
@media only screen and (max-width: 476px) {
  html,
  body {
    font-size: 75%;
  }

  .box-shape {
    height: 130px;
  }

  .custom-padding {
    padding-inline: 3rem;
  }
}
@media only screen and (max-width: 418px) {
  html,
  body {
    font-size: 72.5%;
  }

  .box-shape {
    height: 110px;
  }

  .responsive-modal {
    width: 95%;
  }
}

@media only screen and (max-width: 365px) {
  html,
  body {
    font-size: 65%;
  }

  .custom-padding {
    padding-inline: 2rem;
  }
}



/* overlay loader */
.spanner{
    position:absolute;
    top: 50%;
    left: 0;
    background: #2a2a2a55;
    width: 100%;
    display:block;
    text-align:center;
    height: 100%;
    color: #FFF;
    transform: translateY(-50%);
    z-index: 1000;
    visibility: hidden;
  }

  .overlay{
    position: fixed;
      width: 100%;
      height: 100%;
    background: rgba(0,0,0,0.5);
    visibility: hidden;
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #ffffff;
    font-size: 10px;
    margin: 20% auto 5rem auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  .show{
    visibility: visible;
  }

  .spanner, .overlay{
      opacity: 0;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
  }

  .spanner.show, .overlay.show {
      opacity: 1
  }
/*# sourceMappingURL=style.css.map */
